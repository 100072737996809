<template>
  <div style="margin-bottom: 20px; padding: 15px;">
    <!-- Body -->
    <div>
      <b-row style="background-color: rgba(11,11,38,0.3);  border-radius: 10px !important;">
        <b-col
          cols="3"
          class="m-1 d-flex align-items-sm-start"
        >
          <b-form-group
            class="font-small-3 flex-nowrap"
            label="사이트"
            label-for="branch-site"
            label-cols="4"
            label-cols-md="3"
          >
            <b-form-select
              v-if="authSite"
              id="branch-site"
              ref="branch-site"
              v-model="selectedSite"
              :options="authSite"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); width: 200px;"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <div
            class="organization-chart-container"
            style="padding-left: 10rem"
          >
            <organization-chart
              :datasource="filteredItems"
            >
              <template
                slot-scope="{ nodeData }"
              >
                <b-card
                  no-body
                  :class="{ 'highlighted': nodeData.isSelected }"
                  class="node-template"
                  @click="selectNode(nodeData)"
                >
                  <b-media
                    vertical-align="center"
                    style="padding-left: 5px;"
                  >
                    <template #aside>
                      <b-avatar
                        size="16"
                        :text="avatarText(nodeData.role)"
                        :variant="resolveUserRoleVariant(nodeData.role)"
                      />
                    </template>
                    <div style="margin-left: -10px">
                      <div class="mb-0 text-success">
                        {{ nodeData.userid }}
                      </div>
                      <div class="mb-0">
                        {{ nodeData.nickname }}
                      </div>
                    </div>
                  </b-media>
                </b-card>
              </template>
            </organization-chart>
          </div>
        </b-col>
      </b-row>
    </div>

    <div
      class="row mt-1"
    >
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="float-right"
          :to="{ name: 'branch-create' }"
        >
          총판등록
        </b-button>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormSelect, BButton,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BRANCH_ORGANIZATION_CHART,
  FETCH_BRANCH_ORGANIZATION_CHART_SUB,
} from '@/store/branch/action'

import { avatarText } from '@core/utils/filter'

import OrganizationChart from 'vue-organization-chart'
import '@core/scss/vue/libs/style.min.css'

const branchStore = createNamespacedHelpers('branchStore')
export default {
  name: 'BranchOragnizationChart',
  components: {
    BCard,
    BRow,
    BCol,

    BFormGroup,
    BFormSelect,
    BButton,

    BMedia,
    BAvatar,

    OrganizationChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      selectedSite: localStorage.getItem('authSiteSelected'),
      selectedNode: null,

      avatarText,
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranchOrganizationChart: 'fetchBranchOrganizationChart',
    }),
    filteredItems() {
      let filteredData = this.fetchBranchOrganizationChart
      filteredData = filteredData[0] || {}
      return filteredData
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.fetchData()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchOrganizationChart: FETCH_BRANCH_ORGANIZATION_CHART,
      $fetchBranchOrganizationChartSub: FETCH_BRANCH_ORGANIZATION_CHART_SUB,
    }),
    async fetchData() {
      await this.$fetchBranchOrganizationChart({
        site: this.selectedSite,
        userid: this.userData.userid,
      })
      await this.selectNode(this.filteredItems)
    },
    async selectNode(nodeData) {
      // 이미 선택된 노드를 다시 클릭했을 때 아무런 동작을 하지 않도록 함
      if (this.selectedNode && this.selectedNode === nodeData) {
        return
      }
      if (this.selectedNode && this.selectedNode !== nodeData) {
        this.$set(this.selectedNode, 'isSelected', false)
      }
      this.$set(nodeData, 'isSelected', !nodeData.isSelected)
      this.selectedNode = nodeData
      this.$emit('selectNode', nodeData)

      await this.$fetchBranchOrganizationChartSub({
        site: this.selectedSite,
        userid: this.selectedNode.userid,
      })
    },

    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
  },
}
</script>

<style>
/* vue-organization-chart */
.organization-chart-container {
    overflow-x: auto; /* 가로 스크롤 활성화 */
    overflow-y: auto; /* 세로 스크롤 활성화 */
    width: fit-content; /* 필요에 따라 조정 */
    max-height: 100%; /* 필요에 따라 조정 */
}
.orgchart .node {
  width: 100px !important;
  font-size: 10px;
  color: white;
}
.orgchart .node>div {
  margin: 1px;
  /*padding: 2px;*/
}
.orgchart .node>div.card {
    background-color: rgb(145 142 145 / 39%);
}

.node-template {
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.highlighted {
  background: linear-gradient(118deg, #43971e, rgba(11, 154, 35, 0.7)) !important;
  box-shadow: 0 0 10px 1px rgba(218, 225, 13, 0.7) !important;
  border-radius: 4px !important;
  border: 2px solid rgba(116, 165, 97, 0.7) !important; /* 테두리 색 추가 */
}

</style>
